import React, { StrictMode, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Home = lazy(() => import("pages/home"));
const Room = lazy(() => import("pages/room"));
const Login = lazy(() => import("pages/login"));
const OfflineSettings = lazy(() => import("pages/offlineSettings"));
const OfflineRoom = lazy(() => import("pages/offlineRoom"));
const OnlineSettings = lazy(() => import("pages/onlineSettings"));
const Loading = lazy(() => import("pages/loading"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/room/:id" Component={Room} />
          <Route path="/login" Component={Login} />
          <Route path="/offline/settings" Component={OfflineSettings} />
          <Route path="/offline/room" Component={OfflineRoom} />
          <Route path="/online/settings" Component={OnlineSettings} />
          <Route path="/" Component={Home} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </StrictMode>
);
